/*
Licensed to the Apache Software Foundation (ASF) under one
or more contributor license agreements.  See the NOTICE file
distributed with this work for additional information
regarding copyright ownership.  The ASF licenses this file
to you under the Apache License, Version 2.0 (the
"License"); you may not use this file except in compliance
with the License.  You may obtain a copy of the License at

  http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing,
software distributed under the License is distributed on an
"AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
KIND, either express or implied.  See the License for the
specific language governing permissions and limitations
under the License.
*/

/**
* Dicom tags description
* @module lib/scripts/loaders/dicomdict
*/


export default class DicomDictionary {

  constructor() {
    this.TAGS = [
      /* eslint-disable */
      [0x0002, 0x0000, 'OB', 'FileMetaInfoGroupLength'],
      [0x0002, 0x0001, 'OB', 'FileMetaInformationVersion'],
      [0x0002, 0x0002, 'UI', 'MediaStoredSOPClassUID'],

      [0x0002, 0x0003, 'UI', 'MediaStoredSOPInstanceUID'],
      [0x0002, 0x0010, 'UI', 'TransferSyntaxUID'],
      [0x0002, 0x0012, 'UI', 'ImplementationClassUID'],
      [0x0002, 0x0013, 'UI', 'ImplementationVersionName'],
      [0x0002, 0x0016, 'UI', 'SourceApplicationEntityTitle'],
      [0x0002, 0x0100, 'UI', 'PrivateInformationCreatorUID'],
      [0x0002, 0x0102, 'UI', 'PrivateInformation'],

      [0x0008, 0x0000, 'UL', 'IdentifyingGroupLength'],
      [0x0008, 0x0001, 'UI', 'LengthToEnd'],
      [0x0008, 0x0005, 'CS', 'SpecificCharacterSet'],
      [0x0008, 0x0006, 'SQ', 'LanguageCodeSequence'],
      [0x0008, 0x0008, 'CS', 'ImageType'],
      [0x0008, 0x0010, 'SH', 'RecognitionCode'],
      [0x0008, 0x0012, 'DA', 'InstanceCreationDate'],
      [0x0008, 0x0013, 'TM', 'InstanceCreationTime'],
      [0x0008, 0x0014, 'UI', 'InstanceCreatorUID'],
      [0x0008, 0x0016, 'UI', 'SOPClassUID'],
      [0x0008, 0x0018, 'UI', 'SOPInstanceUID'],
      [0x0008, 0x001A, 'UI', 'RelatedGeneralSOPClassUID'],
      [0x0008, 0x001B, 'UI', 'OriginalSpecializedSOPClassUID'],
      [0x0008, 0x0020, 'DA', 'StudyDate'],
      [0x0008, 0x0021, 'DA', 'SeriesDate'],
      [0x0008, 0x0022, 'DA', 'AcquisitionDate'],
      [0x0008, 0x0023, 'DA', 'ContentDate'],
      [0x0008, 0x0024, 'DA', 'OverlayDate'],
      [0x0008, 0x0025, 'DA', 'CurveDate'],
      [0x0008, 0x002A, 'DT', 'AcquisitionDateTime'],
      [0x0008, 0x0030, 'TM', 'StudyTime'],
      [0x0008, 0x0031, 'TM', 'SeriesTime'],
      [0x0008, 0x0032, 'TM', 'AcquisitionTime'],
      [0x0008, 0x0033, 'TM', 'ContentTime'],
      [0x0008, 0x0034, 'TM', 'OverlayTime'],
      [0x0008, 0x0035, 'TM', 'CurveTime'],
      [0x0008, 0x0040, 'US', 'DataSetType'],
      [0x0008, 0x0041, 'LO', 'DataSetSubtype'],
      [0x0008, 0x0042, 'CS', 'NuclearMedicineSeriesType'],
      [0x0008, 0x0050, 'SH', 'AccessionNumber'],
      [0x0008, 0x0051, 'SQ', 'IssuerOfAccessionNumberSequence'],
      [0x0008, 0x0052, 'CS', 'QueryRetrieveLevel'],
      [0x0008, 0x0054, 'AE', 'RetrieveAETitle'],
      [0x0008, 0x0056, 'CS', 'InstanceAvailability'],
      [0x0008, 0x0058, 'UI', 'FailedSOPInstanceUIDList'],
      [0x0008, 0x0060, 'CS', 'Modality'],
      [0x0008, 0x0070, 'XX', 'Manufacturer'],
      [0x0008, 0x0080, 'XX', 'InstitutionName'],
      [0x0008, 0x0081, 'XX', 'InstitutionAddress'],
      [0x0008, 0x0090, 'XX', 'ReferringPhysicansName'],
      [0x0008, 0x1010, 'XX', 'StationName'],
      [0x0008, 0x1030, 'XX', 'StudyDescription'],
      [0x0008, 0x103E, 'LO', 'SeriesDescription'],
      [0x0008, 0x1040, 'XX', 'InstitutionDepartmentName'],
      [0x0008, 0x1050, 'XX', 'PerformingPhysicansName'],
      [0x0008, 0x1060, '"XX', '"Unkown'],
      [0x0008, 0x1080, 'XX', 'AdmittingDiagnosesDescription'],
      [0x0008, 0x1090, 'XX', 'ManufacturterModelName'],
      [0x0008, 0x1111, 'XX', 'ReferencedPerformedProcStepSeq'],
      [0x0008, 0x1140, 'XX', 'ReferencedImageSequence'],
      [0x0008, 0x1150, 'UI', 'ReferencedSOP​ClassUID'],
      [0x0008, 0x1155, 'UI', 'ReferencedSOP​InstanceUID'],

      [0x0008, 0x2111, '"XX', '"DerivationDescription'],
      [0x0008, 0x2112, '"XX', '"SourceImageSequence'],
    
      [0x0009, 0x0010, 'LO', 'PrivateCreator'],
      [0x0009, 0x0011, '??', '"Unknown'],
      [0x0009, 0x0012, '??', '"Unknown'],
    
      [0x0009, 0x1001, 'XX', 'PrivateTag'],
      [0x0009, 0x1002, 'XX', 'PrivateTag'],
      [0x0009, 0x1004, 'XX', 'PrivateTag'],
      [0x0009, 0x1027, 'XX', 'PrivateTag'],
      [0x0009, 0x1030, 'XX', 'PrivateTag'],
      [0x0009, 0x1031, 'XX', 'PrivateTag'],
      [0x0009, 0x10E3, 'XX', 'PrivateTag'],
      [0x0009, 0x10E9, 'XX', 'PrivateTag'],

      [0x0010, 0x0000, 'UL', 'PatientGroupLength'],
      [0x0010, 0x0010, 'PN', 'PatientName'],
      [0x0010, 0x0020, 'LO', 'PatientID'],
      [0x0010, 0x0021, 'LO', 'IssuerOfPatientID'],
      [0x0010, 0x0022, 'CS', 'TypeOfPatientID'],
      [0x0010, 0x0024, 'SQ', 'IssuerOfPatientIDQualifiersSequence'],
      [0x0010, 0x0030, 'DA', 'PatientBirthDate'],
      [0x0010, 0x0032, 'TM', 'PatientBirthTime'],
      [0x0010, 0x0040, 'CS', 'PatientGender'],
      [0x0010, 0x0050, 'SQ', 'PatientInsurancePlanCodeSequence'],
      [0x0010, 0x0101, 'SQ', 'PatientPrimaryLanguageCodeSequence'],
      [0x0010, 0x0102, 'SQ', 'PatientPrimaryLanguageModifierCodeSequence'],
      [0x0010, 0x1000, 'LO', 'OtherPatientIDs'],
      [0x0010, 0x1001, 'PN', 'OtherPatientNames'],
      [0x0010, 0x1002, 'SQ', 'OtherPatientIDsSequence'],
      [0x0010, 0x1005, 'PN', 'PatientBirthName'],
      [0x0010, 0x1010, 'AS', 'PatientAge'],
      [0x0010, 0x1020, 'DS', 'PatientSize'],
      [0x0010, 0x1021, 'SQ', 'PatientSizeCodeSequence'],
      [0x0010, 0x1030, 'DS', 'PatientWeight'],
      [0x0010, 0x1040, 'LO', 'PatientAddress'],
      [0x0010, 0x1050, 'LO', 'InsurancePlanIdentification'],
      [0x0010, 0x1060, 'PN', 'PatientMotherBirthName'],
      [0x0010, 0x1080, 'LO', 'MilitaryRank'],
      [0x0010, 0x1081, 'LO', 'BranchOfService'],
      [0x0010, 0x1090, 'LO', 'MedicalRecordLocator'],
      [0x0010, 0x2000, 'LO', 'MedicalAlerts'],
      [0x0010, 0x2110, 'LO', 'Allergies'],
      [0x0010, 0x2150, 'LO', 'CountryOfResidence'],
      [0x0010, 0x2152, 'LO', 'RegionOfResidence'],
      [0x0010, 0x2154, 'SH', 'PatientTelephoneNumbers'],
      [0x0010, 0x2160, 'SH', 'EthnicGroup'],
      [0x0010, 0x2180, 'SH', 'Occupation'],
      [0x0010, 0x21A0, 'CS', 'SmokingStatus'],
      [0x0010, 0x21B0, 'LT', 'AdditionalPatientHistory'],
      [0x0010, 0x21C0, 'US', 'PregnancyStatus'],
      [0x0010, 0x21D0, 'DA', 'LastMenstrualDate'],
      [0x0010, 0x21F0, 'LO', 'PatientReligiousPreference'],
      [0x0010, 0x2201, 'LO', 'PatientSpeciesDescription'],
      [0x0010, 0x2202, 'SQ', 'PatientSpeciesCodeSequence'],
      [0x0010, 0x2203, 'CS', 'PatientSexNeutered'],
      [0x0010, 0x2210, 'CS', 'AnatomicalOrientationType'],
      [0x0010, 0x2292, 'LO', 'PatientBreedDescription'],
      [0x0010, 0x2293, 'SQ', 'PatientBreedCodeSequence'],
      [0x0010, 0x2294, 'SQ', 'BreedRegistrationSequence'],
      [0x0010, 0x2295, 'LO', 'BreedRegistrationNumber'],
      [0x0010, 0x2296, 'SQ', 'BreedRegistryCodeSequence'],
      [0x0010, 0x2297, 'PN', 'ResponsiblePerson'],
      [0x0010, 0x2298, 'CS', 'ResponsiblePersonRole'],
      [0x0010, 0x2299, 'LO', 'ResponsibleOrganization'],
      [0x0010, 0x4000, 'LT', 'PatientComments'],
      [0x0010, 0x9431, 'FL', 'ExaminedBodyThickness'],

      [0x0018, 0x0000, 'UL', 'AcquisitionGroupLength'],
      [0x0018, 0x0020, 'XX', 'ScanningSequence'],
      [0x0018, 0x0021, 'XX', 'SequenceVariant'],
      [0x0018, 0x0022, 'XX', 'ScanOptions'],
      [0x0018, 0x0023, 'XX', 'MrAcquisionType'],
      // Add new tag from 2019-01-23
      [0x0018, 0x0024, '??', '"SequenceName'],
      [0x0018, 0x0025, 'XX', 'AngioFlag'],
      [0x0018, 0x0050, 'XX', 'SliceThickness'],
      [0x0018, 0x0060, 'DS', 'KVP'],
      [0x0018, 0x0080, 'XX', 'RepetitionTime'],
      [0x0018, 0x0081, 'XX', 'EchoTime'],
      [0x0018, 0x0082, 'XX', 'InversionTime'],
      [0x0018, 0x0083, 'XX', 'NumberOfAverages'],
      [0x0018, 0x0084, 'XX', 'ImagingFrequency'],
      [0x0018, 0x0085, 'XX', 'ImagedNucleus'],
      [0x0018, 0x0086, 'XX', 'EchoNumber'],
      [0x0018, 0x0087, 'XX', 'MagneticFieldStrength'],
      [0x0018, 0x0088, 'XX', 'SpacingBetweenSlices'],
      [0x0018, 0x0089, 'XX', 'NumberOfPhaseEncodingSteps'],
      [0x0018, 0x0090, 'XX', 'DataCollectionDiameter'],
      [0x0018, 0x0091, 'XX', 'EchoTrainLength'],
      [0x0018, 0x0093, 'XX', 'ProcentSampling'],
      [0x0018, 0x0094, 'XX', 'ProcentPhaseFieldOfView'],
      [0x0018, 0x0095, 'XX', 'PixelBandwidth'],
      [0x0018, 0x1000, 'XX', 'DeviceSerialNumber'],
      [0x0018, 0x1002, "??", 'DeviceUID'],
      [0x0018, 0x1003, "??", 'DeviceID'],
      [0x0018, 0x1004, "??", 'PlateID'],
      [0x0018, 0x1005, "??", 'GeneratorID'],
      [0x0018, 0x1006, "??", 'GridID'],
      [0x0018, 0x1007, "??", '"CassetteID'],
      [0x0018, 0x1008, "??", 'GantryID'],
      [0x0018, 0x1010, "??", 'SecondaryCaptureDeviceID'],
      [0x0018, 0x1011, "??", 'HardcopyCreationDeviceID'],
      [0x0018, 0x1012, "??", 'DateOfSecondaryCapture'],
      [0x0018, 0x1014, "??", 'TimeOfSecondaryCapture'],
      [0x0018, 0x1016, "??", 'SecondaryCaptureDeviceManufacturer'],
      [0x0018, 0x1017, "??", 'HardcopyDeviceManufacturer'],
      [0x0018, 0x1018, "??", 'SecondaryCaptureDeviceModelName'],
      [0x0018, 0x1019, "??", 'SecondaryCaptureDeviceSoftwareVers'],
      [0x0018, 0x101A, "??", 'HardcopyDeviceSoftwareVersion'],
      [0x0018, 0x101B, "??", 'HardcopyDeviceModelName'],
    
      [0x0018, 0x1020, 'XX', 'SoftwareVersions'],
      [0x0018, 0x1030, 'XX', 'ProtocolName'],
      [0x0018, 0x1081, 'XX', '"LowR-RValue'],
      [0x0018, 0x1082, 'XX', '"HiR-RValue'],
      [0x0018, 0x1083, 'XX', '"IntervalsAcquired'],
      [0x0018, 0x1084, 'XX', '"IntervalsRejected'],
    
      [0x0018, 0x1088, 'XX', 'HeartRate'],
      [0x0018, 0x1090, 'XX', 'CardiacNumberOfImages'],
      [0x0018, 0x1094, 'XX', 'TriggerWindow'],
      [0x0018, 0x1100, 'XX', 'ReconstructionDiameter'],
      [0x0018, 0x1110, 'XX', 'DistanceSourceToDetector'],
      [0x0018, 0x1111, 'XX', 'DistanceSourceToPatient'],
      [0x0018, 0x1115, 'XX', 'Unknown'],
      [0x0018, 0x1120, 'XX', 'GantryDetectorTilt'],
      [0x0018, 0x1130, 'XX', 'TableHeight'],
      [0x0018, 0x1140, 'XX', 'RotationDirection'],
      [0x0018, 0x1150, 'XX', 'ExposureTime'],
      [0x0018, 0x1151, 'XX', 'XRayTubeCurrent'],
      [0x0018, 0x1152, 'XX', 'Exposure'],
      [0x0018, 0x115e, 'XX', '"Unknown'],

      [0x0018, 0x1160, 'XX', 'FilterType'],
      [0x0018, 0x1164, 'XX', '"unknown'],
      [0x0018, 0x1166, 'XX', '"unknown'],
      [0x0018, 0x1168, 'XX', '"unknown'],
    
      [0x0018, 0x1170, 'XX', 'GeneratorPower'],
      [0x0018, 0x1180, 'XX', '"Unknown'],
      [0x0018, 0x1190, 'XX', 'FocalSpot'],
      [0x0018, 0x1210, 'XX', 'ConvolutionKernel'],
      [0x0018, 0x1250, 'XX', 'ReceiveCoilName'],
      [0x0018, 0x1310, 'XX', 'AcquisionMatrix'],
      [0x0018, 0x1312, 'XX', 'InPlanePhaseEncodingDirection'],
      [0x0018, 0x1314, 'XX', 'FlipAngle'],
      [0x0018, 0x1315, 'XX', 'VariableFlipAngleFlag'],
      [0x0018, 0x1316, 'XX', 'SAR'],
      [0x0018, 0x1400, 'XX', 'unknown'],

      [0x0018, 0x1600, 'XX', '"ShutterShape'],
      [0x0018, 0x1602, 'XX', 'ShutterLeftVerticalEdge'],
      [0x0018, 0x1604, 'XX', '"ShutterRightVerticalEdge'],
      [0x0018, 0x1606, 'XX', '"ShutterUpperHorizontalEdge'],
      [0x0018, 0x1608, 'XX', '"ShutterLowerHorizontalEdge'],
      [0x0018, 0x1610, 'XX', '"CenterOfCircularShutter'],
      [0x0018, 0x1612, 'XX', '"RadiusOfCircularShutter'],
      [0x0018, 0x1620, 'XX', '"VerticesOfPolygonalShutter'],
      [0x0018, 0x1622, 'XX', '"ShutterPresentationValue'],
      [0x0018, 0x1623, 'XX', '"ShutterOverlayGroup'],
      [0x0018, 0x1624, 'XX', '"ShutterPresentationColorCIELabVal'],
    
      [0x0018, 0x1700, 'XX', 'Unknown'],
      [0x0018, 0x1702, 'XX', 'Unknown'],
      [0x0018, 0x1704, 'XX', 'Unknown'],
      [0x0018, 0x1706, 'XX', 'Unknown'],
      [0x0018, 0x1708, 'XX', 'Unknown'],

      [0x0018, 0x4000, 'XX', '"AcquisitionComments'],
      [0x0018, 0x5000, 'XX', 'OutputPower'],
      [0x0018, 0x5020, 'XX', 'Unknown'],
      [0x0018, 0x5021, 'XX', '"Unknown'],

      [0x0018, 0x5100, 'XX', 'PatientPosition'],
      [0x0018, 0x5101, 'XX', 'ViewPosition'],
      [0x0018, 0x6000, 'XX', 'Unknown'],
    
      [0x0018, 0x9301, 'XX', 'CTAcquisitionTypeSequence'],
      [0x0018, 0x9302, 'XX', 'AcquisitionType'],
      [0x0018, 0x9303, 'XX', 'TubeAngle'],
      [0x0018, 0x9304, 'XX', 'CTAcquisitionDetailsSequence'],
      [0x0018, 0x9305, 'XX', 'RevolutionTime'],
      [0x0018, 0x9306, 'XX', 'SingleCollimationWidth'],
      [0x0018, 0x9307, 'XX', 'TotalCollimationWidth'],
      [0x0018, 0x9308, 'XX', 'CTTableDynamicsSequence'],
      [0x0018, 0x9309, 'XX', 'TableSpeed'],
      [0x0018, 0x9310, 'XX', 'TableFeedPerRotation'],
    
      [0x0018, 0x9311, 'XX', 'SpiralPitchFactor'],
      [0x0018, 0x9312, 'XX', 'CTGeometrySequence'],
      [0x0018, 0x9313, 'XX', 'DataCollectionCenterPatient'],
      [0x0018, 0x9314, 'XX', 'CTReconstructionSequence'],
      [0x0018, 0x9315, 'XX', 'ReconstructionAlgorithm'],
      [0x0018, 0x9316, 'XX', 'ConvolutionKernelGroup'],
      [0x0018, 0x9317, 'XX', 'ReconstructionFieldOfView'],
      [0x0018, 0x9318, 'XX', 'ReconstructionTargetCenterPatient'],
      [0x0018, 0x9319, 'XX', 'ReconstructionAngle'],
      [0x0018, 0x9320, 'XX', 'ImageFilter'],
      [0x0018, 0x9321, 'XX', 'CTExposureSequenc'],
      [0x0018, 0x9322, 'XX', 'ReconstructionPixelSpacing'],
      [0x0018, 0x9323, 'XX', 'ExposureModulationType'],
      [0x0018, 0x9324, 'XX', 'EstimatedDoseSaving'],
      [0x0018, 0x9325, 'XX', 'CTXRayDetailsSequence'],
      [0x0018, 0x9326, 'XX', 'CTPositionSequence'],
      [0x0018, 0x9327, 'XX', 'TablePosition'],
      [0x0018, 0x9328, 'XX', 'ExposureTimeInMilliSec'],
      [0x0018, 0x9329, 'XX', 'CTImageFrameTypeSequence'],
      [0x0018, 0x9330, 'XX', 'XRayTubeCurrentInMilliAmps'],
    
      [0x0019, 0x0010, 'LO', 'PrivateCreator'],
      [0x0019, 0x1002, 'XX', 'NumberOfCellsInDetector'],
      [0x0019, 0x1003, 'XX', 'CellsNumberAtTheta'],
      [0x0019, 0x1004, 'XX', 'CellsSpacing'],
      [0x0019, 0x100F, 'XX', 'HorizFrameOfRef'],
      [0x0019, 0x1011, 'XX', 'SeriesContrast'],
      [0x0019, 0x1012, 'XX', 'LastPSeq'],
      [0x0019, 0x1013, 'XX', 'StartNumberOfBaseLine'],
      [0x0019, 0x1014, 'XX', 'EndNumberOfBaseLine'],
      [0x0019, 0x1015, 'XX', 'StartNumberForEnchancedScans'],
      [0x0019, 0x1016, 'XX', 'EndNumberForEnchancedScans'],
      [0x0019, 0x1017, 'XX', 'SeriesPlane'],
      [0x0019, 0x1018, 'XX', 'FirstScanRas'],
      [0x0019, 0x1019, 'XX', 'FirstScanLocation'],
      [0x0019, 0x101A, 'XX', 'LastScanRas'],
      [0x0019, 0x101B, 'XX', 'LastScanLoc'],
      [0x0019, 0x101E, 'XX', 'DisplayFieldOfView'],
      [0x0019, 0x1023, 'XX', 'TableSpeed'],
      [0x0019, 0x1024, 'XX', 'MidScanTime'],
      [0x0019, 0x1025, 'XX', 'MidScanFlag'],
      [0x0019, 0x1026, 'XX', 'DegreesOfAzimuth'],
      [0x0019, 0x1027, 'XX', 'GantryPeriod'],
      [0x0019, 0x102A, 'XX', 'XRayOnPosition'],
      [0x0019, 0x102B, 'XX', 'XRayOffPosition'],
      [0x0019, 0x102C, 'XX', 'NumberOfTriggers'],
      [0x0019, 0x102E, 'XX', 'AngleOfFirstView'],
      [0x0019, 0x102F, 'XX', 'TriggerFrequency'],
      [0x0019, 0x1039, 'XX', 'ScanFovType'],
      [0x0019, 0x1040, 'XX', 'StatReconFlag'],
      [0x0019, 0x1041, 'XX', 'ComputeType'],
      [0x0019, 0x1042, 'XX', 'SegmentNumber'],
      [0x0019, 0x1043, 'XX', 'TotalSegmentsRequested'],
      [0x0019, 0x1044, 'XX', 'InterscanDelay'],
      [0x0019, 0x1047, 'XX', 'ViewCompressionFactor'],
      [0x0019, 0x104A, 'XX', 'TotalNoOfRefChannels'],
      [0x0019, 0x104B, 'XX', 'DataSizeForScanData'],
      [0x0019, 0x1052, 'XX', 'ReconPostProcflag'],
      [0x0019, 0x1057, 'XX', 'CTWaterNumber'],
      [0x0019, 0x1058, 'XX', 'CTBoneNumber'],
      [0x0019, 0x105A, 'XX', 'AcquisitionDuration'],
      [0x0019, 0x105E, 'XX', 'NumberOfChannels'],
      [0x0019, 0x105F, 'XX', 'IncrementBetweenChannels'],
      [0x0019, 0x1060, 'XX', 'StartingView'],
      [0x0019, 0x1061, 'XX', 'NumberOfViews'],
      [0x0019, 0x1062, 'XX', 'IncrementBetweenViews'],
      [0x0019, 0x106A, 'XX', 'DependantOnNoViewsProcessed'],
      [0x0019, 0x107D, 'DS', 'SecondEcho'],
      [0x0019, 0x107E, 'SS', 'NumberOfEchoes'],
      [0x0019, 0x107F, 'DS', 'TableDelta'],
      [0x0019, 0x1081, 'SS', 'Contiguous'],
      [0x0019, 0x1084, 'DS', 'PeakSAR'],
      [0x0019, 0x1087, 'DS', 'CardiacRepetitionTime'],
      [0x0019, 0x1088, 'SS', 'ImagesPerCardiacCycle'],
      [0x0019, 0x108A, 'SS', 'ActualReceiveGainAnalog'],
      [0x0019, 0x108B, 'SS', 'ActualReceiveGainDigital'],
      [0x0019, 0x108D, 'DS', 'DelayAfterTrigger'],
      [0x0019, 0x108F, 'SS', 'Swappf'],
      [0x0019, 0x1090, 'SS', 'PauseInterval'],
      [0x0019, 0x1091, 'DS', 'PulseTime'],
      [0x0019, 0x1092, 'SL', 'SliceOffsetOnFreqAxis'],
      [0x0019, 0x1093, 'DS', 'CenterFrequency'],
      [0x0019, 0x1094, 'SS', 'TransmitGain'],
      [0x0019, 0x1095, 'SS', 'AnalogReceiverGain'],
      [0x0019, 0x1096, 'SS', 'DigitalReceiverGain'],
      [0x0019, 0x1097, 'SL', 'BitmapDefiningCVs'],
      [0x0019, 0x109B, 'SS', 'PulseSeqMode'],
      [0x0019, 0x109C, 'LO', 'PulseSeqName'],
      [0x0019, 0x109D, 'DT', 'PulseSeqDate'],
      [0x0019, 0x109E, 'LO', 'InternalPulseSeqName'],
      [0x0019, 0x109F, 'SS', 'TransmittingCoil'],
      [0x0019, 0x10A0, 'SS', 'SurfaceCoilType'],
      [0x0019, 0x10A1, 'SS', 'ExtremityCoilFlag'],
      [0x0019, 0x10A2, 'SL', 'RawDataRunNumber'],
      [0x0019, 0x10A3, 'UL', 'CalibratedFieldStrength'],
      [0x0019, 0x10A4, 'SS', 'SATFatWaterBone'],
      [0x0019, 0x10A7, 'DS', 'UserData01'],
      [0x0019, 0x10A8, 'DS', 'UserData02'],
      [0x0019, 0x10A9, 'DS', 'UserData03'],
      [0x0019, 0x10AA, 'DS', 'UserData04'],
      [0x0019, 0x10AB, 'DS', 'UserData05'],
      [0x0019, 0x10AC, 'DS', 'UserData06'],
      [0x0019, 0x10AD, 'DS', 'UserData07'],
      [0x0019, 0x10AE, 'DS', 'UserData08'],
      [0x0019, 0x10AF, 'DS', 'UserData09'],
      [0x0019, 0x10B0, 'DS', 'UserData10'],
      [0x0019, 0x10B1, 'DS', 'UserData11'],
      [0x0019, 0x10B2, 'DS', 'UserData12'],
      [0x0019, 0x10B3, 'DS', 'UserData13'],
      [0x0019, 0x10B4, 'DS', 'UserData14'],
      [0x0019, 0x10B5, 'DS', 'UserData15'],
      [0x0019, 0x10B6, 'DS', 'UserData16'],
      [0x0019, 0x10B7, 'DS', 'UserData17'],
      [0x0019, 0x10B8, 'DS', 'UserData18'],
      [0x0019, 0x10B9, 'DS', 'UserData19'],
      [0x0019, 0x10BA, 'DS', 'UserData20'],
      [0x0019, 0x10BB, 'DS', 'UserData21'],
      [0x0019, 0x10BC, 'DS', 'UserData22'],
      [0x0019, 0x10BD, 'DS', 'UserData23'],
      [0x0019, 0x10BE, 'DS', 'ProjectionAngle'],
      [0x0019, 0x10C0, 'SS', 'SaturationPlanes'],
      [0x0019, 0x10C2, 'SS', 'SATLocationR'],
      [0x0019, 0x10C3, 'SS', 'SATLocationL'],
      [0x0019, 0x10C4, 'SS', 'SATLocationA'],
      [0x0019, 0x10C5, 'SS', 'SATLocationP'],
      [0x0019, 0x10C6, 'SS', 'SATLocationH'],
      [0x0019, 0x10C7, 'SS', 'SATLocationF'],
      [0x0019, 0x10C8, 'SS', 'SATThicknessR-L'],
      [0x0019, 0x10C9, 'SS', 'SATThicknessA-P'],
      [0x0019, 0x10CA, 'SS', 'SATThicknessH-F'],
      [0x0019, 0x10CB, 'SS', 'PrescribedFlowAxis'],
      [0x0019, 0x10CC, 'SS', 'VelocityEncoding'],
      [0x0019, 0x10CD, 'SS', 'ThicknessDisclaimer'],
      [0x0019, 0x10CE, 'SS', 'PrescanType'],
      [0x0019, 0x10CF, 'SS', 'PrescanStatus'],
      [0x0019, 0x10D2, 'SS', 'ProjectionAlgorithm'],
      [0x0019, 0x10D3, 'SH', 'ProjectionAlgorithm'],
      [0x0019, 0x10D5, 'SS', 'FractionalEcho'],
      [0x0019, 0x10D7, 'SS', 'CardiacPhases'],
      [0x0019, 0x10D8, 'SS', 'VariableEchoflag'],
      [0x0019, 0x10D9, 'DS', 'ConcatenatedSAT'],
      [0x0019, 0x10DF, 'DS', 'UserData'],
      [0x0019, 0x10E0, 'DS', 'UserData'],
      [0x0019, 0x10E2, 'DS', 'VelocityEncodeScale'],
      [0x0019, 0x10F2, 'SS', 'FastPhases'],
      [0x0019, 0x10F9, 'DS', 'TransmissionGain'],

      [0x0020, 0x0000, 'UL', 'RelationshipGroupLength'],
      [0x0020, 0x000D, 'UI', 'StudyInstanceID'],
      [0x0020, 0x000E, 'UI', 'SeriesInstanceID'],
      [0x0020, 0x0010, 'IS', 'StudyID'],
      [0x0020, 0x0011, 'IS', 'SeriesNumber'],
      [0x0020, 0x0012, 'IS', 'AcquisionNumber'],
      [0x0020, 0x0013, 'IS', 'ImageNumber'],
      [0x0020, 0x0020, 'IS', 'PatientOrientation'],
      [0x0020, 0x0032, 'DS', 'ImagePosition'],
      [0x0020, 0x0037, 'DS', 'ImageOrientation'],
      [0x0020, 0x0040, 'XX', 'PositionReferenceIndicator'],
      [0x0020, 0x0041, 'XX', 'SliceLocation'],
      [0x0020, 0x0052, 'UI', 'FrameOfRefefernceID'],
      [0x0020, 0x0060, 'XX', 'Laterality'],
      [0x0020, 0x1002, 'XX', 'ImagesInAcquision'],
      [0x0020, 0x1040, 'LO', 'PositionReferenceIndicator'],
      [0x0020, 0x1041, 'DS', 'SliceLocation'],
      [0x0020, 0x9056, 'XX', 'StackId'],
      [0x0020, 0x9057, 'XX', 'InStackPositionNumber'],

      [0x0021, 0x0010, 'LO', 'PrivateCreator'],
      [0x0021, 0x1003, 'XX', 'SeriesFromWhichPrescribed'],
      [0x0021, 0x1005, 'XX', 'GenesisVersionNow'],
      [0x0021, 0x1007, 'XX', 'SeriesRecordChecksum'],
      [0x0021, 0x1018, 'XX', 'GenesisVersionNow'],
      [0x0021, 0x1019, 'XX', 'AcqreconRecordChecksum'],
      [0x0021, 0x1020, 'XX', 'TableStartLocation'],
      [0x0021, 0x1035, 'XX', 'SeriesFromWhichPrescribed'],
      [0x0021, 0x1036, 'XX', 'ImageFromWhichPrescribed'],
      [0x0021, 0x1037, 'XX', 'ScreenFormat'],
      [0x0021, 0x104A, 'XX', 'AnatomicalReferenceForScout'],
      [0x0021, 0x104F, 'XX', 'LocationsInAcquisition'],
      [0x0021, 0x1050, 'XX', 'GraphicallyPrescribed'],
      [0x0021, 0x1051, 'XX', 'RotationFromSourceXRot'],
      [0x0021, 0x1052, 'XX', 'RotationFromSourceYRot'],
      [0x0021, 0x1053, 'XX', 'RotationFromSourceZRot'],
      [0x0021, 0x1054, 'XX', 'ImagePosition'],
      [0x0021, 0x1055, 'XX', 'ImageOrientation'],
      [0x0021, 0x1056, 'XX', 'IntegerSlop'],
      [0x0021, 0x1057, 'XX', 'IntegerSlop'],
      [0x0021, 0x1058, 'XX', 'IntegerSlop'],
      [0x0021, 0x1059, 'XX', 'IntegerSlop'],
      [0x0021, 0x105A, 'XX', 'IntegerSlop'],
      [0x0021, 0x105B, 'XX', 'FloatSlop'],
      [0x0021, 0x105C, 'XX', 'FloatSlop'],
      [0x0021, 0x105D, 'XX', 'FloatSlop'],
      [0x0021, 0x105E, 'XX', 'FloatSlop'],
      [0x0021, 0x105F, 'XX', 'FloatSlop'],
      [0x0021, 0x1081, 'XX', 'AutoWindowLevelAlpha'],
      [0x0021, 0x1082, 'XX', 'AutoWindowLevelBeta'],
      [0x0021, 0x1083, 'XX', 'AutoWindowLevelWindow'],
      [0x0021, 0x1084, 'XX', 'ToWindowLevelLevel'],
      [0x0021, 0x1090, 'XX', 'TubeFocalSpotPosition'],
      [0x0021, 0x1091, 'XX', 'BiopsyPosition'],
      [0x0021, 0x1092, 'XX', 'BiopsyTLocation'],
      [0x0021, 0x1093, 'XX', 'BiopsyRefLocation'],

      [0x0023, 0x0010, 'LO', 'PrivateCreator'],
      [0x0023, 0x1001, 'XX', 'NumberOfSeriesInStudy'],
      [0x0023, 0x1002, 'XX', 'NumberOfUnarchivedSeries'],
      [0x0023, 0x1010, 'XX', 'ReferenceImageField'],
      [0x0023, 0x1050, 'XX', 'SummaryImage'],
      [0x0023, 0x1070, 'XX', 'StartTimeSecsInFirstAxial'],
      [0x0023, 0x1074, 'SL', 'NoofUpdatesToHeader'],
      [0x0023, 0x107d, 'SS', 'IndicatesIfTheStudyHasCompleteInfo'],
      [0x0023, 0x1080, 'LO', 'CRFilmFormat'],

      [0x0025, 0x0010, 'LO', 'PrivateCreator'],
      [0x0025, 0x1006, 'SS', 'LastPulseSequenceUsed'],
      [0x0025, 0x1007, 'SL', 'ImagesInSeries'],
      [0x0025, 0x1010, 'SL', 'LandmarkCounter'],
      [0x0025, 0x1011, 'SS', 'NumberOfAcquisitions'],
      [0x0025, 0x1014, 'SL', 'IndicatesNoofUpdatesToHeader'],
      [0x0025, 0x1017, 'SL', 'SeriesCompleteFlag'],
      [0x0025, 0x1018, 'SL', 'NumberOfImagesArchived'],
      [0x0025, 0x1019, 'SL', 'LastImageNumberUsed'],
      [0x0025, 0x101A, 'SH', 'PrimaryReceiverSuiteAndHost'],
      [0x0025, 0x101B, 'OB', 'ProtocolDataBlock'],

      [0x0027, 0x0010, 'LO', 'PrivateCreator'],
      [0x0027, 0x1006, 'XX', 'ImageArchiveFlag'],
      [0x0027, 0x1010, 'XX', 'ScoutType'],
      [0x0027, 0x101C, 'XX', 'VmaMamp'],
      [0x0027, 0x101D, 'XX', 'VmaPhase'],
      [0x0027, 0x101E, 'XX', 'VmaMod'],
      [0x0027, 0x101F, 'XX', 'VmaClip'],
      [0x0027, 0x1020, 'XX', 'SmartScanOnOffFlag'],
      [0x0027, 0x1030, 'XX', 'ForeignImageRevision'],
      [0x0027, 0x1031, 'XX', 'ImagingMode'],
      [0x0027, 0x1032, 'XX', 'PulseSequence'],
      [0x0027, 0x1033, 'XX', 'ImagingOptions'],
      [0x0027, 0x1035, 'XX', 'PlaneType'],
      [0x0027, 0x1036, 'XX', 'ObliquePlane'],
      [0x0027, 0x1040, 'XX', 'RASLetterOfImageLocation'],
      [0x0027, 0x1041, 'XX', 'ImageLocation'],
      [0x0027, 0x1042, 'XX', 'CenterRCoordOfPlaneImage'],
      [0x0027, 0x1043, 'XX', 'CenterACoordOfPlaneImage'],
      [0x0027, 0x1044, 'XX', 'CenterSCoordOfPlaneImage'],
      [0x0027, 0x1045, 'XX', 'NormalRCoord'],
      [0x0027, 0x1046, 'XX', 'NormalACoord'],
      [0x0027, 0x1047, 'XX', 'NormalSCoord'],
      [0x0027, 0x1048, 'XX', 'RCoordOfTopRightCorner'],
      [0x0027, 0x1049, 'XX', 'ACoordOfTopRightCorner'],
      [0x0027, 0x104A, 'XX', 'SCoordOfTopRightCorner'],
      [0x0027, 0x104B, 'XX', 'RCoordOfBottomRightCorner'],
      [0x0027, 0x104C, 'XX', 'ACoordOfBottomRightCorner'],
      [0x0027, 0x104D, 'XX', 'SCoordOfBottomRightCorner'],
      [0x0027, 0x1050, 'XX', 'TableStartLocation'],
      [0x0027, 0x1051, 'XX', 'TableEndLocation'],
      [0x0027, 0x1060, 'FL', 'ImageDimensionX'],
      [0x0027, 0x1061, 'FL', 'ImageDimensionY'],
      [0x0027, 0x1062, 'FL', 'NumberOfExcitations'],

      [0x0028, 0x0000, 'UL', 'ImagePresentationGroupLength'],
      [0x0028, 0x0002, 'US', 'SamplesPerPixel'],
      [0x0028, 0x0004, 'CS', 'PhotometricInterpretation'],
      [0x0028, 0x0006, 'US', 'Planar​Configuration'],
      [0x0028, 0x0008, 'XX', 'NumberOfFrames'],
      [0x0028, 0x0010, 'US', 'Rows'],
      [0x0028, 0x0011, 'US', 'Columns'],
      [0x0028, 0x0030, 'DS', 'PixelSpacing'],
      [0x0028, 0x0100, 'US', 'BitsAllocated'],
      [0x0028, 0x0101, 'US', 'BitsStored'],
      [0x0028, 0x0102, 'US', 'HighBit'],
      [0x0028, 0x0103, 'US', 'PixelRepresentation'],
      [0x0028, 0x0106, 'XX', 'SmallestImagePixelValue'],
      [0x0028, 0x0107, 'XX', 'LargestImagePixelValue'],
      [0x0028, 0x0120, 'US', 'PixelAddingValue'],
      [0x0028, 0x0301, 'CS', 'BurnderInAnnotation'],
      [0x0028, 0x0303, 'CS', 'Longitudinal​Temporal​Information​Modified'],
      [0x0028, 0x1050, 'DS', 'WindowCenter'],
      [0x0028, 0x1051, 'DS', 'WindowWidth'],
      [0x0028, 0x1052, 'DS', 'RescaleIntercept'],
      [0x0028, 0x1053, 'DS', 'RescaleSlope'],
      [0x0028, 0x1054, 'LO', 'RescaleType'],
      [0x0028, 0x1055, 'LO', 'Window​Center​Width​Explanation'],

      [0x0029, 0x0010, 'LO', 'PrivateCreator'],
      [0x0029, 0x1015, 'SL', 'LowerRangeOfPixels1h'],
      [0x0029, 0x1016, 'SL', 'LowerRangeOfPixels1i'],
      [0x0029, 0x1017, 'SL', 'LowerRangeOfPixels2'],
      [0x0029, 0x1018, 'SL', 'UpperRangeOfPixels2'],
      [0x0029, 0x1026, 'SS', 'VersionOfTheHdrStruct'],
      [0x0029, 0x1031, 'LO', 'PrivateTag'],
      [0x0029, 0x1032, 'UL', 'PrivateTag'],
      [0x0029, 0x1033, 'UL', 'PrivateTag'],
      [0x0029, 0x1034, 'SL', 'AdvantageCompOverflow'],
      [0x0029, 0x1035, 'SL', 'AdvantageCompUnderflow'],

      [0x0032, 0x0000, 'UL', 'StudyGroupLength'],
      [0x0032, 0x000c, 'XX', 'StrudyPriorityId'],
      [0x0032, 0x1020, 'XX', 'ScheduledStudyLocation'],
      [0x0032, 0x1030, 'XX', 'ReasonForStudy'],
      [0x0032, 0x1060, 'XX', 'RequestedProcedureDescription'],

      [0x0038, 0x0010, 'XX', 'AdmissionId'],
      [0x0038, 0x0050, 'XX', 'SpecialNeeds'],
      [0x0038, 0x0500, 'XX', 'PatientState'],
    
      [0x0040, 0x0000, 'UL', 'TextGroupLength'],
      [0x0040, 0x0242, 'XX', 'PerformedStationName'],
      [0x0040, 0x0243, 'XX', 'PerformedLocation'],
      [0x0040, 0x0244, 'XX', 'PerformedProcedureStepStartDate'],
      [0x0040, 0x0245, 'XX', 'PerformedProcedureStepStartTime'],
      [0x0040, 0x0253, 'XX', 'PerformedProcedureStepId'],
      [0x0040, 0x0254, 'XX', 'PerformedProcedureStepDescription'],
      [0x0040, 0x0255, 'LO', 'PerformedProcedureTypeDescription'],
      [0x0040, 0x0280, 'XX', '"CommentsOnPerformedProcedureStep'],
      [0x0040, 0x0321, 'XX', 'FilmConsumptionSequence'],
    
      [0x0040, 0x1001, 'XX', 'RequestedProcedureId'],
      [0x0040, 0x1002, 'XX', 'Unknown'],
      [0x0040, 0x1003, 'XX', 'Unknown'],
      [0x0040, 0x1004, 'XX', 'PatientTransportArrangments'],
      [0x0040, 0x1005, 'XX', 'RequestedProcedureLocation'],
      [0x0040, 0x1010, 'XX', 'NamesOfIntendentRecipientsOfResults'],
      [0x0040, 0x1400, 'XX', 'RequestedProcedureComments'],

      [0x0040, 0x2400, 'XX', 'ImagingServiceRequestComments'],
      [0x0040, 0x3001, 'XX', 'Unknown'],
    
      [0x0043, 0x0010, 'LO', 'PrivateCreator'],
      [0x0043, 0x1001, 'XX', 'BitmapOfPrescanOptions'],
      [0x0043, 0x1002, 'XX', 'GradientOffsetInX'],
      [0x0043, 0x1003, 'XX', 'GradientOffsetInY'],
      [0x0043, 0x1004, 'XX', 'GradientOffsetInZ'],
      [0x0043, 0x1005, 'XX', 'ImgIsOriginalOrUnoriginal'],
      [0x0043, 0x1006, 'XX', 'NumberOfEPIShots'],
      [0x0043, 0x1007, 'XX', 'ViewsPerSegment'],
      [0x0043, 0x1008, 'XX', 'RespiratoryRateBpm'],
      [0x0043, 0x1009, 'XX', 'RespiratoryTriggerPoint'],
      [0x0043, 0x100A, 'XX', 'TypeOfReceiverUsed'],
      [0x0043, 0x100B, 'XX', 'PeakRateOfChangeOfGradientField'],
      [0x0043, 0x100C, 'XX', 'LimitsInUnitsOfPercent'],
      [0x0043, 0x100D, 'XX', 'PSDEstimatedLimit'],
      [0x0043, 0x100E, 'XX', 'PSDEstimatedLimitInTeslaPerSecond'],
      [0x0043, 0x100F, 'XX', 'Saravghead'],
      [0x0043, 0x1010, 'XX', 'WindowValue'],
      [0x0043, 0x1011, 'XX', 'TotalInputViews'],
      [0x0043, 0x1012, 'XX', 'XRayChain'],
      [0x0043, 0x1013, 'XX', 'DeconKernelParameters'],
      [0x0043, 0x1014, 'XX', 'CalibrationParameters'],
      [0x0043, 0x1015, 'XX', 'TotalOutputViews'],
      [0x0043, 0x1016, 'XX', 'NumberOfOverranges'],
      [0x0043, 0x1017, 'XX', 'IBHImageScaleFactors'],
      [0x0043, 0x1018, 'XX', 'BBHCoefficients'],
      [0x0043, 0x1019, 'XX', 'NumberOfBBHChainsToBlend'],
      [0x0043, 0x101A, 'XX', 'StartingChannelNumber'],
      [0x0043, 0x101B, 'XX', 'PpscanParameters'],
      [0x0043, 0x101C, 'XX', 'GEImageIntegrity'],
      [0x0043, 0x101D, 'XX', 'LevelValue'],
      [0x0043, 0x101E, 'XX', 'DeltaStartTime'],
      [0x0043, 0x101F, 'XX', 'MaxOverrangesInAView'],
      [0x0043, 0x1020, 'XX', 'AvgOverrangesAllViews'],
      [0x0043, 0x1021, 'XX', 'CorrectedAfterGlowTerms'],
      [0x0043, 0x1025, 'XX', 'ReferenceChannels'],
      [0x0043, 0x1026, 'XX', 'NoViewsRefChansBlocked'],
      [0x0043, 0x1027, 'XX', 'ScanPitchRatio'],
      [0x0043, 0x1028, 'XX', 'UniqueImageIden'],
      [0x0043, 0x1029, 'XX', 'HistogramTables'],
      [0x0043, 0x102A, 'XX', 'UserDefinedData'],
      [0x0043, 0x102B, 'XX', 'PrivateScanOptions'],
      [0x0043, 0x102C, 'XX', 'EffectiveEchoSpacing'],
      [0x0043, 0x102D, 'XX', 'StringSlopField1'],
      [0x0043, 0x102E, 'XX', 'StringSlopField2'],
      [0x0043, 0x102F, 'XX', 'RawDataType'],
      [0x0043, 0x1030, 'XX', 'RawDataType'],
      [0x0043, 0x1031, 'XX', 'RACordOfTargetReconCenter'],
      [0x0043, 0x1032, 'XX', 'RawDataType'],
      [0x0043, 0x1033, 'XX', 'NegScanspacing'],
      [0x0043, 0x1034, 'XX', 'OffsetFrequency'],
      [0x0043, 0x1035, 'XX', 'UserUsageTag'],
      [0x0043, 0x1036, 'XX', 'UserFillMapMSW'],
      [0x0043, 0x1037, 'XX', 'UserFillMapLSW'],
      [0x0043, 0x1038, 'XX', 'User25_48'],
      [0x0043, 0x1039, 'XX', 'SlopInt6_9'],
      [0x0043, 0x1040, 'XX', 'TriggerOnPosition'],
      [0x0043, 0x1041, 'XX', 'DegreeOfRotation'],
      [0x0043, 0x1042, 'XX', 'DASTriggerSource'],
      [0x0043, 0x1043, 'XX', 'DASFpaGain'],
      [0x0043, 0x1044, 'XX', 'DASOutputSource'],
      [0x0043, 0x1045, 'XX', 'DASAdInput'],
      [0x0043, 0x1046, 'XX', 'DASCalMode'],
      [0x0043, 0x1047, 'XX', 'DASCalFrequency'],
      [0x0043, 0x1048, 'XX', 'DASRegXm'],
      [0x0043, 0x1049, 'XX', 'DASAutoZero'],
      [0x0043, 0x104A, 'XX', 'StartingChannelOfView'],
      [0x0043, 0x104B, 'XX', 'DASXmPattern'],
      [0x0043, 0x104C, 'XX', 'TGGCTriggerMode'],
      [0x0043, 0x104D, 'XX', 'StartScanToXrayOnDelay'],
      [0x0043, 0x104E, 'XX', 'DurationOfXrayOn'],
      [0x0043, 0x1060, 'XX', 'SlopInt10_17'],
      [0x0043, 0x1061, 'XX', 'ScannerStudyEntityUID'],
      [0x0043, 0x1062, 'XX', 'ScannerStudyID'],
      [0x0043, 0x106F, 'XX', 'ScannerTableEntry'],
      [0x0043, 0x107D, 'US', 'ReconModeFlagWord '],
      [0x0043, 0x1080, 'LO', 'CoilIDData'],
      [0x0043, 0x1081, 'LO', 'GECoilName'],
      [0x0043, 0x1082, 'LO', 'SystemConfigurationInformation'],
      [0x0043, 0x1083, 'DS', 'AssetRFactors'],
      [0x0043, 0x1084, 'LO', 'AdditionalAssetData'],
      [0x0043, 0x1089, 'LO', 'GoverningBodyAndSARDefinition'],
      [0x0043, 0x108A, 'CS', 'PrivateInPlanePhaseEncodingDirection'],
      [0x0043, 0x1090, 'LO', 'SARDefinition'],
      [0x0043, 0x1091, 'DS', 'SARValue'],
      [0x0043, 0x1095, 'LO', 'PrescanReuseString'],
      [0x0043, 0x1096, 'CS', 'ContentQualification'],
      [0x0043, 0x1097, 'LO', 'ImageFilteringParameters'],
      [0x0043, 0x109A, 'IS', 'RxStackIdentification'],
      [0x0043, 0x10AA, 'LO', 'AdditionalFilteringParameters'],
      [0x0043, 0x10B1, 'SS', 'ExcitationMode'],

      [0x0045, 0x0010, 'LO', 'PrivateCreator'],
      [0x0045, 0x1001, 'XX', 'NumberOfMacroRowsInDetector'],
      [0x0045, 0x1002, 'XX', 'MacroWidthAtISOCenter'],
      [0x0045, 0x1003, 'XX', 'DASType'],
      [0x0045, 0x1004, 'XX', 'DASGain'],
      [0x0045, 0x1006, 'XX', 'TableDirectionInOrOut'],
      [0x0045, 0x1007, 'XX', 'ZSmoothingFactor'],
      [0x0045, 0x1008, 'XX', 'ViewWeightingMode'],
      [0x0045, 0x1009, 'XX', 'SigmaRowNumberWhichRowsWereUsed'],
      [0x0045, 0x100A, 'XX', 'MinimumDasValueFoundInTheScanData'],
      [0x0045, 0x100B, 'XX', 'MaximumOffsetShiftValueUsed'],
      [0x0045, 0x100C, 'XX', 'NumberOfViewsShifted'],
      [0x0045, 0x100D, 'XX', 'ZTrackingFlag'],
      [0x0045, 0x100E, 'XX', 'MeanZError'],
      [0x0045, 0x100F, 'XX', 'ZTrackingMaximumError'],
      [0x0045, 0x1010, 'XX', 'StartingViewForRow2a'],
      [0x0045, 0x1011, 'XX', 'NumberOfViewsInRow2a'],
      [0x0045, 0x1012, 'XX', 'StartingViewForRow1a'],
      [0x0045, 0x1013, 'XX', 'SigmaMode'],
      [0x0045, 0x1014, 'XX', 'NumberOfViewsInRow1a'],
      [0x0045, 0x1015, 'XX', 'StartingViewForRow2b'],
      [0x0045, 0x1016, 'XX', 'NumberOfViewsInRow2b'],
      [0x0045, 0x1017, 'XX', 'StartingViewForRow1b'],
      [0x0045, 0x1018, 'XX', 'NumberOfViewsInRow1b'],
      [0x0045, 0x1021, 'XX', 'PrivateTag'],
      [0x0045, 0x1022, 'XX', 'PrivateTag'],
      [0x0045, 0x1032, 'XX', 'PrivateTag'],


      [0x0053, 0x0010, 'LO', 'PrivateCreator'],
      [0x0053, 0x1020, 'XX', 'PrivateTag'],
      [0x0053, 0x1040, 'XX', 'PrivateTag'],
      [0x0053, 0x1041, 'XX', 'PrivateTag'],
      [0x0053, 0x1042, 'XX', 'PrivateTag'],
      [0x0053, 0x1043, 'XX', 'PrivateTag'],
      [0x0053, 0x1062, 'XX', 'PrivateTag'],

      [0x0088, 0x0140, 'XX', 'StorageMediaFileSetUid'],
      [0x0088, 0x0200, 'XX', 'IconImageSequence'],

      [0x2001, 0x1003, 'XX', 'Unknown'],
      [0x2001, 0x100a, 'XX', 'Unknown'],
      [0x2001, 0x106e, 'XX', 'Unknown'],
      [0x2005, 0x0012, 'XX', 'Unknown'],
      [0x2005, 0x0013, 'XX', 'Unknown'],
      [0x2005, 0x0014, 'XX', 'Unknown'],
      [0x2005, 0x1213, 'XX', 'Unknown'],
      [0x2005, 0x1406, 'XX', 'Unknown'],

      [0x5533, 0x1203, 'XX', '"Unknown'],
      [0x5533, 0x5511, 'XX', '"Unknown'],

      [0x7fe0, 0x0000, 'IM', 'PixelDataGroupLength'],
      [0x7fe0, 0x0010, 'IM', 'PixelData'],
      /* eslint-enable */
    ];
  } // constructor

  /**
  * Get vr string code from group + element codes
  * @param {number} group - group of the tag
  * @param {number} element - element of the tag
  * return {string} vr
  */
  getVr(group, element) {
    let vr = '';
    const numTags = this.TAGS.length;
    for (let i = 0; i < numTags; i++) {
      if ((this.TAGS[i][0] === group) && (this.TAGS[i][1] === element)) {
        vr = this.TAGS[i][2];
      }
    } // for (i) all
    if (element === 0) {
      vr = 'UL';
    }
    return vr;
  } // get VR

  /**
  * Get descripotion string  from group + element codes
  * @param {number} group - group of the tag
  * @param {number} element - element of the tag
  * return {string} description
  */
  getTextDesc(group, element) {
    let tagDesc = '?';
    const numTags = this.TAGS.length;
    for (let i = 0; i < numTags; i++) {
      if ((this.TAGS[i][0] === group) && (this.TAGS[i][1] === element)) {
        tagDesc = this.TAGS[i][3];
      }
    } // for (i) all
    return tagDesc;
  } // get tag text description
}
