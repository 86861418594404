export default {
  // special demo dialog file locations
  //

  demoUrls: [
  ],

  googleCloudDemoActivce: false,
  arrMenuGoogle: [
    {
      text: 'Demo lungs AA',
      tooltip: 'Load some lungs model',
    },
    {
      text: 'Demo head BB',
      tooltip: 'Load some strange head',
    },
    {
      text: 'Demo alien CC',
      tooltip: 'Write here smth please',
    },
  ],
  demoWomanPelvisPrefix : '',
  demoWomanPelvisUrls : [
  ],
  demoLungsPrefix: '',
  demoLungsUrls: [
  ],

};